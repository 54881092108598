$(document).ready(function() {
  $('.slick-carousel').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true
  });
  $(document).on('click', '.slick-carousel .slick-slide', function() {
    var $carousel = $(this).closest('.slick-carousel');
    var nextSlide = parseInt($carousel.slick('slickCurrentSlide')) + 1;
    $carousel.slick('slickGoTo', nextSlide);
  });
});
