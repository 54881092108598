$(function() {
  $countdownWeeks = $('#countdown-weeks');
  $countdownDays = $('#countdown-days');
  $countdownHours = $('#countdown-hours');
  $countdownMinutes = $('#countdown-minutes');
  $countdownSeconds = $('#countdown-seconds');
  updateCountdown();
  setInterval(updateCountdown, 1000);
});

function updateCountdown() {
  var remaining = getTimeRemaining();
  if (remaining.total < 0) {
    return;
  }
  if (remaining.weeks > 0) {
    $countdownWeeks.children('i').text(formatNumber(remaining.weeks));
    $countdownWeeks.removeClass('l-timer-item--hidden');
  } else {
    $countdownWeeks.children('i').text(0);
  }
  if (remaining.days > 0) {
    $countdownDays.children('i').text(formatNumber(remaining.days));
    $countdownDays.removeClass('l-timer-item--hidden');
  } else {
    $countdownDays.children('i').text(0);
  }
  $countdownHours.children('i').text(formatNumber(remaining.hours));
  $countdownHours.removeClass('l-timer-item--hidden');
  $countdownMinutes.children('i').text(formatNumber(remaining.mins));
  $countdownMinutes.removeClass('l-timer-item--hidden');
  $countdownSeconds.children('i').text(formatNumber(remaining.secs));
  $countdownSeconds.removeClass('l-timer-item--hidden');
}

function getTimeRemaining() {
  var t = Date.parse('2019-07-20T16:00:00-07:00') - Date.parse(new Date());
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  var days = Math.floor((t / (1000 * 60 * 60 * 24)) % 7);
  var weeks = Math.floor(t / (1000 * 60 * 60 * 24 * 7));
  return {
    total: t,
    weeks: weeks,
    days: days,
    hours: hours,
    mins: minutes,
    secs: seconds
  };
}

function formatNumber(n) {
  if (n >= 10) {
    return n.toLocaleString();
  }
  if (n > 0) {
    return '0' + n.toLocaleString();
  }
  return '00';
}
