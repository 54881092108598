$(document).ready(function() {
  $('.background').each(function() {
    var $this = $(this);
    var $slides = $('.background__image', this);
    if ($slides.length > 1) {
      var index = $slides.length - 1;

      function rotate() {
        var next = (index + 1) % $slides.length;
        var $slide;
        if (next === 0) {
          // console.log('Fading out background ' + index);
          $($slides[index]).fadeOut('slow');
        } else {
          // console.log('Fading in background ' + next);
          var previous = index;
          $($slides[next]).fadeIn(1000, function() {
            if (previous !== 0) {
              // console.log('Hiding background ' + previous);
              $($slides[previous]).css('display', 'none');
            }
          });
        }
        index = next;
      }
      setInterval(rotate, 6000);
      $slides.slice(1, $slides.length - 1).each(function() {
        var $this = $(this);
        // console.log('Hiding ', $this);
        $this.css('display', 'none');
      });
    }
  });
});
